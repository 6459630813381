<template lang="pug">
  v-tabs(v-model="activeTab" color="newPrimary" height="40px" show-arrows).app-tabs
    v-tab(
      v-for="tab in tabs"
      :key="tab.name"
      @click="switchTab(tab.value)"
    )
      div
        div {{ tab.label }}
          btn(small).ml-2.tab-count {{ tab.count }}
</template>

<script>
import { CANDIDATES_TABS_LIST, FILTERS_ARGS } from './core/candidates-const'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  props: {
    counts: Object
  },

  mixins: [featureFlagsMixin],

  data: () => ({
    activeTab: 0
  }),

  computed: {
    tabs() {
      return [
        {
          name: CANDIDATES_TABS_LIST.TASKS,
          label: 'Tasks',
          count: this.counts.tasks,
          value: CANDIDATES_TABS_LIST.TASKS,
        },
        {
          name: CANDIDATES_TABS_LIST.WAIT,
          label: 'Wait',
          count: this.counts.wait,
          value: CANDIDATES_TABS_LIST.WAIT
        },
        {
          name: CANDIDATES_TABS_LIST.NEED_AUTHORIZATION,
          label: 'Machtigingen',
          count: this.counts.needAuthorization,
          value: CANDIDATES_TABS_LIST.NEED_AUTHORIZATION
        },
        {
          name: CANDIDATES_TABS_LIST.RESERVE_EXCHANGE,
          label: 'Reserve and exchange queue',
          count: this.counts.reserveExchange,
          value: CANDIDATES_TABS_LIST.RESERVE_EXCHANGE
        },
        {
          name: CANDIDATES_TABS_LIST.RESERVED_EXCHANGED,
          label: 'Reserved and exchanged',
          count: this.counts.reservedExchanged,
          value: CANDIDATES_TABS_LIST.RESERVED_EXCHANGED
        },
        {
          name: CANDIDATES_TABS_LIST.PROCESSED,
          label: 'Processed',
          count: this.counts.processed,
          value: CANDIDATES_TABS_LIST.PROCESSED
        },
        {
          name: CANDIDATES_TABS_LIST.CANCEL,
          label: 'Canceled',
          count: this.counts.canceled_list,
          value: CANDIDATES_TABS_LIST.CANCEL
        },
        {
          name: CANDIDATES_TABS_LIST.ALL,
          label: 'All',
          count: this.counts.all,
          value: CANDIDATES_TABS_LIST.ALL
        },
      ]
    }
  },

  created() {
    this.setTabByName()
  },

  methods: {
    async switchTab(value) {
      if (this.$route.name === value) return
      let cityID = this.$route.query[FILTERS_ARGS.CITY_COURSE] || undefined
      await this.$router.replace({ name: value, query: { city: cityID } })
      this.$emit('change')
    },

    setTabByName() {
      let tabIndex = this.tabs.findIndex(tab => tab.value === this.$route.name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  components: {
    btn: () => import('@/components/global/BtnCustom.vue')
  },

  watch: {
    '$route.query': function () {
      this.setTabByName()
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/tabsNew";
</style>
